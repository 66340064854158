<template>
    <div class="successEditor" v-if="this.addRemoveSuccessModalProps.showAddSuccess">

        <textarea @input="updateSuccessValue($event.target.value)"
            style="width:100vw; height: 40vh; background-color: white;">
        </textarea>

        <div class="buttonsHolder">
            <button :class="saveButtonClass" @click="saveSuccess">
                <span class="saveCaption">{{ addRemoveSuccessModalProps?.successesData?.saveCaption }}</span>
            </button>
            <button :class="cancelButtonClass" @click="cancelChanges">
                <span class="cancelButton">{{ addRemoveSuccessModalProps?.successesData?.rejectCaption }}</span>
            </button>
        </div>
    </div>

    <div class="confirmationHolder" v-if="this.addRemoveSuccessModalProps.showRemoveSuccess">
        <div class="confirmationBox">
            <span>{{ addRemoveSuccessModalProps?.successesData?.successRemoveConfirmation }}</span>

            <div class="buttonsHolder">
                <button class="yesButton" @click="removeYesClick">{{
                    addRemoveSuccessModalProps?.successesData?.yesCaption }}</button>
                <button class="noButton" @click="removeNoClick">{{ addRemoveSuccessModalProps?.successesData?.noCaption
                    }}</button>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import router from '@/main';

export default {
    name: "AddSuccessModal",
    props: {
        addRemoveSuccessModalProps: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            successValue: null,

            saveButtonClass: null,
            cancelButtonClass: null,
        }
    },

    mounted() {
        if (window.Telegram.WebApp.colorScheme === 'dark') {
            this.saveButtonClass = "saveButtonDark";
            this.cancelButtonClass = "cancelButtonDark";
        } else {
            this.saveButtonClass = "saveButtonLight";
            this.cancelButtonClass = "cancelButtonLight";
        }
    },

    methods: {
        updateSuccessValue(successValue) {
            this.successValue = successValue;
        },

        async saveSuccess() {
            var initDataBase64 = btoa(window.Telegram.WebApp.initData);

            await axios
                .post(`${this.hostname}/successes/saveSuccess?initData=${initDataBase64}&success=${this.successValue}`)
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        router.push("/unathorized");
                    } else {
                        router.push('/serverErrorPage')
                    }
                });

            this.$emit('successAdded');
            this.$emit("hideAddSuccessModal");
        },

        cancelChanges() {
            this.showAddSuccess = false;
            this.$emit("hideAddSuccessModal");
        },

        removeYesClick() {
            this.$emit('removeSuccessYes');
        },

        removeNoClick() {
            this.$emit('removeSuccessNo');
        },

        selectEmoji() {

        }
    },
}
</script>

<style scoped>
.successEditor {
    position: fixed;

    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;

    z-index: 999;

    /*background-color: rgba(0,0,0,0.35);*/
}

.buttonsHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.saveButtonDark {
    width: 30vw;
    height: 7vh;
    margin-top: -3vh;
    margin-left: 10vw;
    background-color: var(--button-background-dark);
    border-radius: 100px;
    /*box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;*/
    color: var(--button-label-dark);
    cursor: pointer;
    display: inline-block;
    font-family: Garamond;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
}

.saveButtonLight {
    width: 30vw;
    height: 7vh;
    margin-top: -3vh;
    margin-left: 10vw;
    background-color: var(--button-background-dark);
    border-radius: 100px;
    /*box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;*/
    color: var(--button-label-dark);
    cursor: pointer;
    display: inline-block;
    font-family: Garamond;
    /*padding: 7px 20px;*/
    justify-content: center;
    justify-items: center;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
}

.cancelButtonDark {
    width: 30vw;
    height: 7vh;
    margin-top: -3vh;
    margin-right: 10vw;
    background-color: var(--button-background-dark);
    border-radius: 100px;
    /*box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;*/
    color: var(--button-label-dark);
    cursor: pointer;
    display: inline-block;
    font-family: Garamond;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
}

.cancelButtonLight {
    width: 30vw;
    height: 7vh;
    margin-top: -3vh;
    margin-right: 10vw;
    background-color: var(--button-background-dark);
    border-radius: 100px;
    /*box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;*/
    color: var(--button-label-dark);
    cursor: pointer;
    display: inline-block;
    font-family: Garamond;
    /*padding: 7px 20px;*/
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    justify-content: center;
    justify-items: center;
    border: 0;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
}

.confirmationHolder {
    font-family: Garamond, serif;
    font-size: 3.5vh;

    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;

    width: 100vw;
    height: 100vh;
}

.confirmationBox {
    display: flex;
    flex-direction: column;

    font-family: Garamond, serif;
    font-size: 3.5vh;

    position: fixed;

    width: 50vw;
    height: 20vh;

    padding: 3vh 3vw;

    top: 36vh;
    left: 20vw;

    border-radius: 3vh;
    border: 2px solid #D64933;

    background-color: white;
}

.buttonsHolder {
    display: flex;
    flex-direction: row;

    margin-top: 3vh;
}

.yesButton {
    width: 15vw;
    height: 6vh;
}

.noButton {
    width: 15vw;
    height: 6vh;
}
</style>